$(document).ready ->
	$('form').submit ->
		testSubmit = true
		$('input[type=text],input[type=password]').each ->
			if $(this).val() == '' && $(this).is(':visible')
				testSubmit = false
				$(this).parent().addClass('has-error')

		if $('#action').val() == 'login'
			$('#_username').val(LOGIN_PREFIX + $('#username').val())
		else if $('#action').val() == 'register'
			$('#_register_username').val($('#register_username').val())
		else if $('#action').val() == 'lost'
			$('#_lost_email').val(LOGIN_PREFIX + $('#lost_email').val())
		
		return testSubmit


	$(window).resize ->
		$('form').css('marginTop', $(window).height() / 2 - $('form').outerHeight() / 2);
	$(window).resize()

	$('.login-gcu-gcu .btn').click ->
		$('.login-gcu-gcu').hide()
		$('.login-gcu-login').show()

	$('#toggle-register').click ->
		if ($(this).hasClass('back'))
			if $('#toggle-register').data('back-text') != ''
				$('#toggle-register').text($('#toggle-register').data('back-text'))
			$('#toggle-register').removeClass('back')

			$('#fieldset-lost').slideUp()
			$('#fieldset-register').slideUp()
			$('#fieldset-login').slideDown()

			$('#action').val('login')
		else
			if $('#toggle-lost').data('back-text') != ''
				$('#toggle-lost').text($('#toggle-lost').data('back-text'))
			$('#toggle-lost').removeClass('back')

			$(this).data('back-text', $(this).text())
			$(this).text('Login')
			$(this).addClass('back')

			$('#fieldset-lost').slideUp()
			$('#fieldset-login').slideUp()
			$('#fieldset-register').slideDown()

			$('#action').val('register')

	$('#toggle-lost').click ->
		if ($(this).hasClass('back'))
			if $('#toggle-lost').data('back-text') != ''
				$('#toggle-lost').text($('#toggle-lost').data('back-text'))
			$('#toggle-lost').removeClass('back')

			$('#fieldset-lost').slideUp()
			$('#fieldset-register').slideUp()
			$('#fieldset-login').slideDown()

			$('#action').val('login')
		else
			if $('#toggle-register').data('back-text') != ''
				$('#toggle-register').text($('#toggle-register').data('back-text'))
			$('#toggle-register').removeClass('back')

			$(this).data('back-text', $(this).text())
			$(this).text('Login')
			$(this).addClass('back')

			$('#fieldset-register').slideUp()
			$('#fieldset-login').slideUp()
			$('#fieldset-lost').slideDown()

			$('#action').val('lost')